<template>
<div>
    <v-dialog v-model="rfqDialog" max-width="1000px" scrollable>
        <v-card>
          <v-card-title class="headline">
            Request for Quotation<small class="ml-2">[{{rfqData.PRCODE}}]</small>
            <v-spacer></v-spacer>
            <v-btn icon @click="clickCloseMainDialog()"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
          <v-card-text style="max-height: 900px;">
            <v-form @submit.prevent ref="formRefRFQ">
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="rfqFormData.RFQType" row :disabled="!switchRFQ" >
                  <v-radio label="Lot Bid" value="1" color="header"></v-radio>
                  <v-radio label="Item Bid" value="2" color="header"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="rfqFormData.dodID"
                  :disabled="!switchRFQ"
                  item-text="dodDescription"
                  item-value="dodID"
                  :rules="[formRules.required]"
                  :items="dodList"
                  color="header"
                  dense
                  class="mb-4"
                  label="Delivery Period"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  v-model="rfqFormData.Lists"
                  :loading="loading"
                  :headers="headers"
                  :items="itemList"
                  :options.sync="options"
                  item-key="ppmpItemID"
                  show-select
                  @toggle-select-all="selectAllToggle"
                  :items-per-page="-1"
                  :footer-props="{
                    'items-per-page-options': [-1]
                  }"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    
                  </template>
                  <template v-slot:[`item.UnitCost`]="{ item }">
                    <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
                  </template>
                  <template v-slot:[`item.Amount`]="{ item }">
                    <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                  </template>
                  <template v-slot:[`item.QtyRemaining`]="{ item }">
                    {{parseFloat(item.Qty) - parseFloat(item.IARQTY)}}
                  </template>
                  <template v-slot:[`item.Status`]="{ item }">
                    <v-icon v-if="item.PRstatus == 1 || item.PRstatus >= 3" color="header">mdi-check-circle</v-icon>
                    <v-icon v-else color="amber darken-2">mdi-close-circle</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="mt-5">
            <v-switch
                  v-model="switchRFQ"
                  v-if="showEditRFQ"
                  inset
                  class="mb-4"
                  hide-details
                  color="header"
                  label="Edit Mode"
                ></v-switch>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="clickCloseMainDialog()">Cancel</v-btn>
            <v-btn color="blue darken-2 white--text" @click="confirmGenerateRFQ()" :loading="isLoading"><v-icon>mdi-printer</v-icon>Print</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="dialogSave" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <div class="mx-6">
          Are you sure you want to save the RFQ?
        </div>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" outlined @click="dialogSave = false">Cancel</v-btn>
          <v-btn color="#E45200" class="white--text" @click="saveRFQConfirm()"><v-icon>mdi-check</v-icon> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
    </div>
</template>
    
    <script>
      export default {
	props : {
        rfqData: {},
	},
        data: () => ({
          headers: [
              {
                text: 'Item Name',
                align: 'start',
                sortable: false,
                value: 'ItemName',
              },
              { text: 'Description', value: 'itemDescription' , style: 'max-width: 200px;', sortable: false },
              { text: 'Unit Measure', value: 'UnitMeasureDescription', align: "center", sortable: false},
              { text: 'Qty', value: 'Qty', align: "center", sortable: false },
              { text: 'Unit Cost', value: 'UnitCost', align: "end", sortable: false},
              { text: 'Amount', value: 'Amount', align: "end", sortable: false},
              { text: 'RFQ Status', value: 'Status', align: "center", sortable: false}
            ],
          headersRFQ: [
              {
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              // { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Item Name', value: 'ItemName', sortable: false },
              { text: 'Item Description', value: 'ItemDescription', sortable: false }
            ],
          loading: false,
          prViewItemDialog: false,
           footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
           options: {},
          isLoading: false,
          fadeAwayMessage: {
            show: false,
            type: "success",
            header: "Successfully Added!",
            message: "",
            top: 10,
          },
          rfqDialog: false,
          rfqFormData: {
          RFQType: null,
          dodID: null,
          Lists: []
          },
          dodList: [],
          switchRFQ: false,
          showEditRFQ: false,
          itemList: [],
          expanded2: [],
          totalCount: 0,
          dialogDelete: false,
          dialogSave: false,
          disabledCount: 0
        }),
       watch: {
          rfqData: {
            handler (data) {
            if(data.PRID){
            if(data.RFQType != null && data.dodID != null){
              this.rfqFormData.RFQType = data.RFQType.toString()
              this.rfqFormData.dodID = data.dodID
              this.showEditRFQ = true;
              this.switchRFQ = false;
            }
            else{
              this.showEditRFQ = false;
              this.switchRFQ = true;
            }
            this.rfqDialog = true;
            this.getItems();
            }
            },
            deep: true,
          },
        },
        created(){
          this.getDOD();
          const self = this;
          this.itemList.map(item => {
            if (item.disabled) self.disabledCount += 1
          })
        },
        methods: {

        clickCloseMainDialog () {
            this.rfqFormData = {
              RFQType: null,
              dodID: null,
              Lists: []
              }
            this.itemList =[];
            this.rfqDialog = false;
            this.eventHub.$emit('closeRFQModal', false);
        },
          getItems () {
            let data = new FormData();
               data.append('PRID', this.rfqData.PRID);
                  this.axiosCall(
                  '/pr/rfq/items',
                  'POST',
                  data
              )
              .then((res) => {
                this.loading = false;
                this.itemList = res.data.data.result;
                this.rfqFormData.Lists = this.itemList
                this.selectAllToggle({items: this.itemList, value: true})
                this.selectAllToggle({items: this.itemList, value: false})
              })
          },
          getDOD(){
            this.axiosCall("/get/all/POUtil", "POST", []).then((res) => {
              this.dodList = res.data.data.dod;
            });
          },
          selectAllToggle(props) {
            if(this.rfqFormData.Lists.length != this.itemList.length - this.disabledCount) {
              this.rfqFormData.Lists = [];
              const self = this;
              props.items.forEach(item => {
                if(!item.disabled) {
                  self.rfqFormData.Lists.push(item);
                } 
              });
            } else this.rfqFormData.Lists = [];
          },
          confirmGenerateRFQ(){
            if(this.$refs.formRefRFQ.validate()){
              let items = [];
              this.rfqFormData.Lists.forEach(item => {
                items.push(item.ppmpItemID)
              });
              console.log(items)
              this.isLoading = true;
              let data = new FormData();
              data.append('PRID', this.rfqData.PRID);
              data.append('RFQType', this.rfqFormData.RFQType);
              data.append('dodID', this.rfqFormData.dodID);
              data.append('items', JSON.stringify(items));
                          this.axiosCall(
                        '/pr/generateRFQ',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        let recanvass = 0;
                        if(this.rfqData.IsRecanvassed == 1){
                          recanvass = 1;
                        }
                        this.getItems();
                        window.open(this.apiUrl + "/pr/rfq/" + recanvass + '/' + JSON.stringify(items) + '/' + res.data.data);
                        // this.$refs.rfqFormData.reset();
                        // this.rfqDialog = false;
                        // this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = res.data.message;
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
              }
              else{
                this.isLoading = false;
              }
          },
        }
      }
    </script>
        
    <style scoped>
    .v-dialog--fullscreen, .prModal {
      overflow-x: hidden !important;
    }
    </style>